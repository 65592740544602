import React from 'react'
import { useUser } from './context/user-context'
import FullPageSpinner from './components/Preloader'
import './App.scss'

const loadAuthenticatedApp = () => import('./dashboard-app')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

function App () {
  const user = useUser()
  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  React.useEffect(() => {
    loadAuthenticatedApp()
  }, [])

  return (
    <React.Suspense fallback={<FullPageSpinner/>}>
      {user ? <AuthenticatedApp/> : <UnauthenticatedApp/>}
    </React.Suspense>
  )
}

export default App
